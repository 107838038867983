








































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AreaEditThresholds extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public area: any = {};
  public thresholds: any = {};

  public async created() {
    if (this.id !== null) {
      this.area = this.$store.getters.getAreaFromId(this.id);
      this.$store.dispatch('loadAdminAreaThresholds', {areaId: this.id})
      .then((response: any) => {
        this.thresholds = response;
        this.loading = false;
      })
      .catch((error: any) => {
        this.globalError(error);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  private onSubmit() {
    this.loading = true;
    const payload = {
      areaId: this.id,
      payload: this.thresholds,
    };

    this.$store.dispatch('editAdminAreaThresholds', payload)
    .then((response: any) => {
      this.$q.notify({
          message: `Les niveaux ont été sauvegardés`,
          color: 'teal',
        });
      this.$router.go(-1);
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }
}
